/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"

import IRPageWrapper from "../../layouts/IRPageWrapper"
import IRNav from "../../components/IRNav"
import IRBG from "../../components/IRBG"

class TermsPage extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />>
        <Helmet>
          <title> Terms - E.A. Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="section">
                    <h1 className="title">Website Terms of Use</h1>
                    <IRNav />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" id="port-section">
          <div className="column is-8 is-offset-2">
            This Investor Relations Website ("IR Website") is designed and
            maintained by Insage (MSC) Sdn Bhd ("Insage") and any pages thereof
            including but not limited to the information, text, images, links,
            sound, graphics and video sequences displayed therein are referred
            to as the "Materials". Please read these Terms of Use ("Terms")
            carefully before using this site. By accessing and using this IR
            Website and its Materials, you acknowledge and agree to abide by the
            following Terms. If you do not agree to these Terms, do not use the
            IR Website or download any Materials.
            <br />
            <br />
            USE TERMS:- <br />
            Personal Non-Commercial Use:
            <br />
            <br />
            This IR Website is for personal and non-commercial use. Unless
            otherwise specified or as provided in these Terms, you may not
            modify, copy, distribute, transmit, display, perform, reproduce,
            publish, license, create derivative works from, transfer, or sell
            any information, software, products or services obtained from the
            Materials contained herein.
            <br />
            <br />
            Exclusions of Warrantly and Disclaimers
            <br />
            <br />
            The Materials and IR Website are designed and maintained by Insage
            as a service to its customers and are for informational purposes
            only.
            <br />
            <br />
            You expressly understand and agree that:
            <br />
            <br />
            Your use of the IR Website and Materials is at your sole risk and
            that the services are provided "as is" and "as available" without
            any express or implied warranty of any kind including warranties of
            merchantability, non- infringement of intellectual property, or
            fitness for any particular purpose.
            <br />
            <br />
            Any material downloaded or otherwise obtained through the use of the
            services is done at your own discretion and risk and you will be
            solely responsible for any damage to your computer system or other
            device or loss of data that results from the download of any such
            material.
            <br />
            <br />
            In no event shall Insage or its suppliers be liable for any damages
            whatsoever (including, without limitation, damages for loss of
            profits, business interruption, loss of information) arising out of
            the use of or inability to use the web sites or Materials.
            <br />
            <br />
            The information presented on this IR Website and the Materials
            including facts, views, advice, analyses, opinions and
            recommendations of individuals and organisations are provided and
            made available for general information purposes only. It is not
            intended as investment advice and must not be relied upon as such,
            Insage are not giving or purporting to give or representing or
            holding ourselves out as giving personalised financial, investment,
            tax, legal and other professional advice. User shall always seek the
            relevant professional advice before otherwise making any such
            financial, legal or commercial decisions.
            <br />
            <br />
            No information contained in this website constitutes or would be
            deemed to constitute an invitation or inducement to invest or
            otherwise deal in the shares of the public listed company or an
            invitation or inducement by or on behalf of the public listed
            company or any of its subsidiaries to enter into a contract with
            you.
            <br />
            <br />
            Any facts, views, advice, analyses, opinions and recommendations
            expressed or made available by any individual or organisation are
            those of the respective author(s).
            <br />
            <br />
            Insage cannot and do not endorse, and cannot be responsible for such
            facts, views, advice, analyses, opinions and recommendations
            expressed, or advocate any commercial dealings in any securities,
            investments or classes of securities or investments nor does the
            inclusion of a link to other web site(s) or resources imply any form
            of endorsement by Insage. You must always seek the relevant
            professional advice before otherwise making any such financial,
            legal or commercial decisions.Insage cannot and do not make any
            representation or warranty as to the accuracy or completeness of
            data feeds (including stock quotes, currency rates or other price or
            information feeds) though it is generally believed to be timely and
            accurate.
            <br />
            <br />
            <b>Limitation of Liability:</b>
            <br />
            <br />
            You expressly understand and agree that Insage shall not be liable
            to you for:
            <br />
            <br />
            In no event shall Insage and/or its respective suppliers be liable
            for any special, indirect or consequential damages or any damages
            whatsoever resulting from loss of use, data or profits, whether in
            an action of contract, negligence or other tortious action, arising
            out of or in connection with the use or performance of any software,
            documents, provision of or failure to provide services, or
            information available from the web site.
            <br />
            <br />
            Any loss or damage which may be incurred by you, including but not
            limited to loss or damage as a result of any reliance placed by you
            on the completeness, accuracy or existence of any content, or any
            changes Insage may make to the services, documents, web site,
            content or for any permanent or temporary cessation in the provision
            of the web site (or any features within the web sites); or if
            applicable, your failure to keep your password or account details
            secure and confidential;
            <br />
            <br />
            These limitations on Insage's liability shall apply whether or not
            Insage has been advised of or should have been aware of the
            possibility of any such losses.
            <br />
            <br />
            <b>Indemnification:</b>
            <br />
            <br />
            You agree to indemnify and hold Insage and its directors, officers,
            agents, employees, partners and licensors harmless from any claim or
            demand, including reasonable attorneys' fees, made by any third
            party due to or arising out of content you submit, post, transmit or
            otherwise make available through the IR Website or Materials, your
            use of the IR Website or Materials, your connection to the IR
            Website or Materials, your violation of these Terms of Use or site
            specific Terms of Use, or your violation of any rights of another.
            <br />
            <br />
            <b>Accuracy and Completeness:</b>
            <br />
            <br />
            Insage, its related companies, and its directors, officers, agents,
            employees or contractors do not give any representation or warranty
            as to the timeliness reliability, accuracy or completeness of any
            information on this website, or warrant that any of the functions
            contained in any information on this website or your access to this
            website will be uninterrupted or error-free.
            <br />
            <br />
            The information contained in this website is not comprehensive.
            Despite our efforts, it may not be accurate, up to date or
            applicable to the circumstances of any particular case. We cannot
            accept any liability for any inaccuracies or omissions in this
            website and any decisions you make based on information contained in
            this website are your sole responsibility. Neither Insage, its
            related companies, and its directors, officers, agents, employees or
            contractors accept liability for any direct, indirect, special,
            consequential or other losses or damages of whatsoever kind arising
            out of access to, or the use of this website or any information
            contained in it.
            <br />
            <br />
            <b>Changes and Updates:</b>
            <br />
            <br />
            Insage reserves the right to modify or discontinue, temporarily or
            permanently, IR Websites or Materials (or any part thereof) with or
            without notice. Insage may make changes to IR Websites or Materials,
            or to the products described therein, at any time without notice.
            <br />
            <br />
            <b>Third Party Content:</b>
            <br />
            <br />
            The IR Websites and Materials may contain user or third party
            submitted content; such content is not reviewed, approved or
            endorsed by Insage and is provided solely as a convenience to our
            customers and users. Under no circumstances will Insage be liable in
            any way for any third party submitted or provided content,
            including, but not limited to, any errors or omissions, or damages
            of any kind. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH
            THE USE OF THE MATERIALS IS DONE AT YOUR OWN DISCRETION AND RISK AND
            THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
            SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE
            DOWNLOAD OF ANY SUCH MATERIAL. By your use you agree that you must
            evaluate, and bear all risks associated with, the use of any third
            party content, including any reliance on the accuracy, completeness,
            or usefulness of such content. All postings and use of the IR
            Website or Materials are subject to these Terms of Use and any other
            program and site specific terms.
            <br />
            <br />
            <b>Use of Hyperlink:</b>
            <br />
            <br />
            Insage is not responsible for the content of any other website,
            including any website through which you may have gained access to
            our website or to which you may gain access from our website. We do
            not accept any liability in connection with any such sites or links.
            Where we provide a hyperlink to a third party's website, we do so
            because we believe in good faith that such a website contains or may
            contain material which is relevant to that on our website. Such a
            hyperlink does not signify that Insage has reviewed or approve of
            the connected third party's website or its contents.
            <br />
            <br />
            <b>
              Security, Passwords and Means of Accessing The Site and Content
            </b>
            <br />
            <br />
            You agree not to access or use the IR Website in any manner that
            could damage, disable, overburden, or impair any Insage accounts,
            computer systems or networks. You agree not to attempt to gain
            unauthorized access to any parts of the IR Website or any Insage
            accounts, computer systems or networks. You agree not to interfere
            or attempt to interfere with the proper working of the IR Website or
            any Insage accounts, computer systems or networks. You agree not to
            use any robot, spider, scraper or other automated means to access
            the IR Website or any Insage accounts, computer systems or networks
            without Insage's express written permission.
            <br />
            <br />
            Access to and use of password protected or secure areas of the IR
            Website is restricted to authorized users only. You agree not to
            share your password(s), account information, or access to the IR
            Website. You are responsible for maintaining the confidentiality of
            password(s) and account information, and you are responsible for all
            activities that occur under your password(s) or account(s) or as a
            result of your access to the IR Website. You agree to notify Insage
            immediately of any unauthorized use of your password(s) or
            account(s).
            <br />
            <br />
            <b>Privacy</b>
            <br />
            <br />
            Insage will not share, sell, or rent individual personal information
            unless ordered by a court of law. Information submitted to us is
            only available to those managing this information and for the
            purposes of contacting you or sending you publications or emails
            based on your requests for information.
            <br />
            <br />
            <b>CONTACT INFORMATION</b>
            <br />
            <br />
            If you have any questions or doubts regarding this TOU, please
            contact us at info@insage.com.my
            <br />
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

TermsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default TermsPage
